let API_URL: string = '';

if (process.env.REACT_APP_ENV === 'testing') {
    API_URL = ' http://192.168.29.44:5000/api';
}
if(process.env.REACT_APP_ENV === 'development') {
    API_URL = 'http://3.7.135.152:5000/api';
}

if(process.env.REACT_APP_ENV === 'production') {
    API_URL = 'https://yournidra.com/api';
}

export default API_URL;