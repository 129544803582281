import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar: React.FC = () => {
  return (
    <div className="w-64 bg-white shadow-lg">
      <nav className="mt-6 space-y-2">
        <Link
          to="/user/dashboard"
          className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"
        >
          Overview
        </Link>
        <Link
          to="/user/profile"
          className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"
        >
          Profile
        </Link>
        <Link
          to="/user/questionnaire"
          className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"
        >
          Self Assessment
        </Link>
        <Link
          to="/user/sleep-study"
          className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"
        >
          Sleep Study
        </Link>
        <Link
          to="/user/consult"
          className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"
        >
          Consultation
        </Link>
        <Link
          to="/user/cpap"
          className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"
        >
          Devices
        </Link>
        <Link
          to="/user/referral"
          className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"
        >
          Referrals
        </Link>
        <Link
          to="/user/faq"
          className="block w-full text-left px-6 py-3 text-lg text-gray-600 hover:bg-indigo-50 hover:text-indigo-900 focus:outline-none rounded-md transition-all duration-200"
        >
          FAQs
        </Link>
        <Link
          to="/user/logout"
          className="block w-full text-left px-6 py-3 text-lg text-red-600 hover:bg-red-50 hover:text-red-900 focus:outline-none rounded-md transition-all duration-200"
        >
          Logout
        </Link>
      </nav>
    </div>
  );
};

export default Sidebar;