import React, { useEffect, useState } from 'react';
import Sidebar from '../../Sidebar';
import ProgressCard from '../../ProgressCard';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { format } from 'date-fns';
import API_URL from '../../../../config/config';

const Dashboard: React.FC = () => {
    const [progressData, setProgressData] = useState<any[]>([]);
    const [missingFields, setMissingFields] = useState<string[]>([]);
    const [currentQuestion, setCurrentQuestion] = useState<number>(0);
    const [modalInput, setModalInput] = useState<string>(''); // Default to string
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState<string>(''); // For input validation
    const userName = localStorage.getItem('userName') || 'John Doe';
    const [selectedDate, setSelectedDate] = useState<Date | null>(null); // For date picker

    useEffect(() => {
        // Check for missing fields in localStorage
        const missing: string[] = [];
        const fieldsToCheck = ['firstName', 'lastName', 'email', 'mobile', 'dateOfBirth'];

        fieldsToCheck.forEach((field) => {
            const value = localStorage.getItem(field);
            if (!value || value === 'undefined') {
                missing.push(field);
            }
        });

        if (missing.length > 0) {
            setMissingFields(missing);
            setShowModal(true);
        } else {
            // Fetch progress data, including checking for quiz status
            fetchProgressData();
        }
    }, []);

    const fetchProgressData = async () => {
        const userId = localStorage.getItem('id');
        const token = localStorage.getItem('token');

        if (!token) {
            console.error('No token found in localStorage');
            // Handle token absence (e.g., redirect to login)
            return;
        }

        try {
            // Initialize default progressData
            const progressData = [
                {
                    title: 'Self Assessment',
                    status: 'Pending',
                    date: 'Not Taken',
                    linkTo: '/user/questionnaire',
                },
                {
                    title: 'Sleep Study',
                    status: 'Pending',
                    date: 'Not Taken',
                    linkTo: '/user/sleep-study',
                },
                {
                    title: 'Doctor Consultation',
                    status: 'Pending',
                    date: 'Not Taken',
                    linkTo: '/user/consult',
                },
                {
                    title: 'Device Purchase',
                    status: 'Not Required',
                    date: 'Not Taken',
                    linkTo: '/user/cpap',
                },
            ];

            // Fetch Quiz Data
            const quizResponse = await fetch(`${API_URL}/quiz/user/all/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`, // Attach token here
                },
            });

            if (quizResponse.ok) {
                const quizData = await quizResponse.json();
                // Adjust based on actual structure of quizData
                let quizzesArray = [];

                if (Array.isArray(quizData)) {
                    quizzesArray = quizData;
                } else if (Array.isArray(quizData.data)) {
                    quizzesArray = quizData.data;
                } else if (Array.isArray(quizData.quizData)) {
                    quizzesArray = quizData.quizData;
                }

                if (quizzesArray.length > 0) {
                    const latestQuiz = quizzesArray[0];

                    if (latestQuiz && latestQuiz.createdAt) {
                        progressData[0] = {
                            title: 'Self Assessment',
                            status: 'Completed',
                            date: format(new Date(latestQuiz.createdAt), 'dd MMM yyyy'),
                            linkTo: '/user/questionnaire',
                        };
                    }
                }
            } else {
                const errorText = await quizResponse.text();
                console.error(`Quiz fetch failed with status: ${quizResponse.status}`);
                console.error('Error response:', errorText);

                if (quizResponse.status !== 404) {
                    throw new Error('Failed to fetch quiz data');
                }
            }

            // Fetch Sleep Study Data
            const sleepStudyResponse = await fetch(`${API_URL}/sleep-study/user/all/${userId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`, // Attach token here
                },
            });

            if (sleepStudyResponse.ok) {
                const sleepStudyData = await sleepStudyResponse.json();
                // Adjust based on actual structure of sleepStudyData
                let sleepStudiesArray = [];

                if (Array.isArray(sleepStudyData)) {
                    sleepStudiesArray = sleepStudyData;
                } else if (Array.isArray(sleepStudyData.data)) {
                    sleepStudiesArray = sleepStudyData.data;
                } else if (Array.isArray(sleepStudyData.sleepStudyData)) {
                    sleepStudiesArray = sleepStudyData.sleepStudyData;
                }

                if (sleepStudiesArray.length > 0) {
                    const latestSleepStudy = sleepStudiesArray[0];

                    if (latestSleepStudy && latestSleepStudy.createdAt) {
                        progressData[1] = {
                            title: 'Sleep Study',
                            status: latestSleepStudy.status,
                            date: format(new Date(latestSleepStudy.createdAt), 'dd MMM yyyy'),
                            linkTo: '/user/sleep-study',
                        };
                    }
                }
            } else {
                const errorText = await sleepStudyResponse.text();
                console.error(`Sleep study fetch failed with status: ${sleepStudyResponse.status}`);
                console.error('Error response:', errorText);

                if (sleepStudyResponse.status !== 404) {
                    throw new Error('Failed to fetch sleep study data');
                }
            }

            // Set the updated progress data
            setProgressData(progressData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleModalSubmit = async () => {
        const field = missingFields[currentQuestion];

        // Validate input
        if (field !== 'dateOfBirth' && modalInput.trim() === '') {
            setError('This field cannot be empty');
            return;
        }

        if (field === 'dateOfBirth' && selectedDate === null) {
            setError('Please select your date of birth');
            return;
        }

        // Save field values in localStorage
        if (field === 'dateOfBirth' && selectedDate) {
            const formattedDate = format(selectedDate, 'yyyy-MM-dd');
            localStorage.setItem(field, formattedDate);
        } else {
            localStorage.setItem(field, modalInput.trim());
        }

        // Reset state
        setModalInput('');
        setSelectedDate(null);
        setError('');

        // Move to the next question or close modal
        if (currentQuestion + 1 < missingFields.length) {
            setCurrentQuestion(currentQuestion + 1);
        } else {
            setShowModal(false);

            // Collect updated data
            const updatedData = {
                firstName: localStorage.getItem('firstName'),
                lastName: localStorage.getItem('lastName'),
                email: localStorage.getItem('email'),
                mobile: localStorage.getItem('mobile'),
                dateOfBirth: localStorage.getItem('dateOfBirth'),
            };

            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    setError('Authentication token is missing. Please log in again.');
                    return;
                }

                // Update user API
                const response = await fetch(`${API_URL}/user/update/${localStorage.getItem('id')}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(updatedData),
                });

                const contentType = response.headers.get('content-type');
                if (!response.ok) {
                    if (contentType && contentType.includes('application/json')) {
                        const errorData = await response.json();
                        console.error('Error:', errorData.message || response.statusText);
                    } else {
                        const errorText = await response.text();
                        console.error('Error (HTML):', errorText);
                    }
                    setError('Failed to update user details. Please try again.');
                    return;
                }

                // Refresh page
                window.location.reload();
            } catch (error) {
                console.error('Error:', error);
                setError('An unexpected error occurred. Please try again.');
            }
        }
    };

    const getQuestionLabel = (field: string) => {
        switch (field) {
            case 'firstName':
                return 'What is your first name?';
            case 'lastName':
                return 'What is your last name?';
            case 'email':
                return 'What is your email address?';
            case 'mobile':
                return 'What is your mobile number?';
            case 'dateOfBirth':
                return 'What is your date of birth?';
            default:
                return '';
        }
    };

    return (
        <div className="flex h-screen bg-gray-50 font-sans">
            <Sidebar />
            <div className="flex-1 overflow-y-auto p-4 lg:p-8">
                <header className="mb-8 flex items-center justify-between">
                    <h1 className="text-2xl lg:text-3xl font-bold">Welcome, {userName}</h1>
                </header>

                <div className="space-y-8 relative">
                    {progressData.map((step, index) => (
                        <ProgressCard
                            key={index}
                            title={step.title}
                            status={step.status}
                            date={step.date}
                            linkTo={step.linkTo}
                        />
                    ))}
                </div>

                {/* Modal for collecting missing information */}
                {showModal && (
                    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
                        <div className="bg-white p-8 rounded-lg shadow-lg w-96">
                            {/* Modal Header */}
                            <h2 className="text-xl font-bold mb-2 text-indigo-600">Complete Your Profile</h2>

                            {/* Modal Description */}
                            <p className="text-sm text-gray-600 mb-6">
                                We need a few more details to provide you with a better experience. Please fill out the missing information below.
                            </p>

                            {/* Question Label */}
                            <h3 className="text-lg font-semibold mb-2">
                                {getQuestionLabel(missingFields[currentQuestion])}
                            </h3>

                            {/* Input Field */}
                            {missingFields[currentQuestion] === 'dateOfBirth' ? (
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={(date: Date | null) => setSelectedDate(date)}
                                    dateFormat="dd/MM/yyyy"
                                    className="border border-gray-300 p-2 rounded w-full text-gray-700"
                                    placeholderText="Select your date of birth"
                                />
                            ) : (
                                <textarea
                                    value={modalInput}
                                    onChange={(e) => setModalInput(e.target.value)}
                                    className="border border-gray-300 p-2 rounded w-full text-gray-700"
                                    placeholder={getQuestionLabel(missingFields[currentQuestion])}
                                />
                            )}

                            {/* Validation Error Message */}
                            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}

                            {/* Modal Footer */}
                            <div className="flex justify-end mt-6">
                                <button
                                    onClick={handleModalSubmit}
                                    className="bg-indigo-600 hover:bg-indigo-700 text-white py-2 px-4 rounded transition-all"
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
};

export default Dashboard;