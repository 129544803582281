import React, { useState, useEffect } from 'react';
import Sidebar from '../../Sidebar'; // Import Sidebar component
import { UserProfile } from '../../../../types';
import { format, parseISO, isValid } from 'date-fns'; // Import date-fns

const Profile: React.FC = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [profile, setProfile] = useState<UserProfile>({
        firstName: "Not Shared",
        lastName: "Not Shared",
        email: "Not Shared",
        dateOfBirth: "Not Shared",
        gender: "Not Shared",
        phoneNumber: "Not Shared",
        address: "Not Shared",
    });

    // Utility function to capitalize the first letter of a string
    const capitalizeFirstLetter = (str: string) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    // Utility function to add spaces between camelCase or PascalCase words
    const formatLabel = (label: string) => {
        return label
            .replace(/([A-Z])/g, ' $1')  // Add space before capital letters
            .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
    };

    useEffect(() => {
        // Load user information from localStorage
        const storedDateOfBirth = localStorage.getItem('dateOfBirth') || 'Not Shared';

        const savedProfile: UserProfile = {
            firstName: localStorage.getItem('firstName') || 'Not Shared',
            lastName: localStorage.getItem('lastName') || 'Not Shared',
            email: localStorage.getItem('email') || 'Not Shared',
            dateOfBirth: storedDateOfBirth,
            gender: capitalizeFirstLetter(localStorage.getItem('gender') || 'Not Shared'),
            phoneNumber: localStorage.getItem('mobile') || 'Not Shared',
            address: localStorage.getItem('address') || 'Not Shared', // Assuming address is stored in localStorage
        };

        setProfile(savedProfile);
    }, []);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setProfile(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setIsEditing(false);
        // Optionally update localStorage with new values
        localStorage.setItem('firstName', profile.firstName || '');
        localStorage.setItem('lastName', profile.lastName || '');
        localStorage.setItem('email', profile.email);
        localStorage.setItem('dateOfBirth', profile.dateOfBirth); // Save in 'YYYY-MM-DD' format
        localStorage.setItem('gender', profile.gender);
        localStorage.setItem('mobile', profile.phoneNumber);
        localStorage.setItem('address', profile.address);
    };

    // Function to display date in DD-MM-YYYY format for the UI
    const formatDisplayDate = (dateOfBirth: string) => {
        if (!isValid(parseISO(dateOfBirth))) return 'Not Shared';
        return format(new Date(dateOfBirth), 'dd-MM-yyyy'); // Format date for display
    };

    return (
        <div className="flex h-screen bg-gray-50 font-sans">
            <Sidebar />

            <div className="flex-1 overflow-y-auto p-8">
                <header className="mb-8 flex items-center justify-between">
                    <h1 className="text-3xl font-bold">Your Profile</h1>
                    {!isEditing && (
                        <button
                            onClick={() => setIsEditing(true)}
                            className="bg-darkGreen text-white font-bold py-2 px-4 rounded shadow"
                        >
                            Edit Profile
                        </button>
                    )}
                </header>

                <div className="bg-white rounded-lg shadow-md p-6">
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {/* First Name */}
                            <div className="mb-4">
                                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                                    First Name
                                </label>
                                <input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    value={profile.firstName}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                    className={`mt-1 block w-full border-0 border-b-2 ${isEditing
                                        ? "border-indigo-300 focus:border-indigo-500 focus:ring-0"
                                        : "border-transparent bg-transparent focus:border-indigo-500"
                                        } text-lg`}
                                />
                            </div>

                            {/* Last Name */}
                            <div className="mb-4">
                                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                                    Last Name
                                </label>
                                <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    value={profile.lastName}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                    className={`mt-1 block w-full border-0 border-b-2 ${isEditing
                                        ? "border-indigo-300 focus:border-indigo-500 focus:ring-0"
                                        : "border-transparent bg-transparent focus:border-indigo-500"
                                        } text-lg`}
                                />
                            </div>

                            {/* Gender */}
                            <div className="mb-4">
                                <label htmlFor="gender" className="block text-sm font-medium text-gray-700 mb-1">
                                    Gender
                                </label>
                                {isEditing ? (
                                    <select
                                        name="gender"
                                        id="gender"
                                        value={profile.gender}
                                        onChange={handleInputChange}
                                        className="mt-1 block w-full border-0 border-b-2 border-indigo-300 focus:border-indigo-500 focus:ring-0 text-lg"
                                    >
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Other">Other</option>
                                    </select>
                                ) : (
                                    <input
                                        type="text"
                                        name="gender"
                                        id="gender"
                                        value={profile.gender}
                                        disabled
                                        className="mt-1 block w-full border-transparent bg-transparent text-lg"
                                    />
                                )}
                            </div>

                            {/* Phone Number */}
                            <div className="mb-4">
                                <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700 mb-1">
                                    Phone Number
                                </label>
                                <input
                                    type="text"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    value={profile.phoneNumber}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                    className={`mt-1 block w-full border-0 border-b-2 ${isEditing
                                        ? "border-indigo-300 focus:border-indigo-500 focus:ring-0"
                                        : "border-transparent bg-transparent focus:border-indigo-500"
                                        } text-lg`}
                                />
                            </div>

                            {/* Date of Birth */}
                            <div className="mb-4">
                                <label htmlFor="dateOfBirth" className="block text-sm font-medium text-gray-700 mb-1">
                                    Date of Birth
                                </label>
                                <input
                                    type={isEditing ? "date" : "text"}
                                    name="dateOfBirth"
                                    id="dateOfBirth"
                                    value={
                                        !isEditing
                                            ? formatDisplayDate(profile.dateOfBirth)  // Display in DD-MM-YYYY format when not editing
                                            : profile.dateOfBirth  // When editing, value remains in YYYY-MM-DD format
                                    }
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                    className={`mt-1 block w-full border-0 border-b-2 ${isEditing
                                        ? "border-indigo-300 focus:border-indigo-500 focus:ring-0"
                                        : "border-transparent bg-transparent focus:border-indigo-500"
                                        } text-lg`}
                                />
                            </div>

                            {/* Email */}
                            <div className="mb-4">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                                    Email
                                </label>
                                <input type="email"
                                    name="email"
                                    id="email"
                                    value={profile.email}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                    className={`mt-1 block w-full border-0 border-b-2 ${isEditing
                                        ? "border-indigo-300 focus:border-indigo-500 focus:ring-0"
                                        : "border-transparent bg-transparent focus:border-indigo-500"
                                        } text-lg`}
                                />
                            </div>

                            <div className="mb-4">
                                <label htmlFor="address" className="block text-sm font-medium text-gray-700 mb-1">
                                    Address
                                </label>
                                <textarea
                                    name="address"
                                    id="address"
                                    value={profile.address}
                                    onChange={handleInputChange}
                                    disabled={!isEditing}
                                    rows={4}
                                    className={`mt-1 block w-full border-0 border-b-2 ${isEditing
                                        ? "border-indigo-300 focus:border-indigo-500 focus:ring-0"
                                        : "border-transparent bg-transparent focus:border-indigo-500"
                                        } text-lg`}
                                />
                            </div>

                        </div>

                        {/* Edit Mode Actions */}
                        {isEditing && (
                            <div className="flex justify-end space-x-2 mt-6">
                                <button
                                    type="button"
                                    onClick={() => setIsEditing(false)}
                                    className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded shadow"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    className="bg-darkGreen text-white font-bold py-2 px-4 rounded shadow"
                                >
                                    Save Changes
                                </button>
                            </div>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Profile;