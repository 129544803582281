import React from 'react';
import { Link } from 'react-router-dom';

interface ProgressCardProps {
  title: string;
  status: string;
  date: string;
  linkTo: string;
}

const ProgressCard: React.FC<ProgressCardProps> = ({ title, status, date, linkTo }) => {
  const getStatusStyle = (status: string) => {
    switch (status) {
      case 'Completed':
        return 'bg-green-500 text-white'; // Green tick for completed
      case 'Pending':
        return 'bg-yellow-500 text-white'; // Yellow for pending
      default:
        return 'bg-gray-300 text-gray-700'; // Grey for not started
    }
  };

  const getCardSideColor = (status: string) => {
    switch (status) {
      case 'Completed':
        return 'border-green-500'; // Green left border for completed
      case 'Pending':
        return 'border-yellow-500'; // Yellow left border for pending
      default:
        return 'border-gray-300'; // Grey left border for not started
    }
  };

  return (
    <Link to={linkTo} className="block mb-8"> {/* Add margin-bottom to increase spacing between cards */}
      <div className="relative flex items-start space-x-4 w-full">
        {/* Card Content */}
        <div className={`flex items-center bg-white shadow-lg p-6 rounded-lg flex-1 border-l-8 ${getCardSideColor(status)}`}>
          {/* Increased padding and shadow size for a bigger card */}
          
          {/* Status Icon */}
          <div className={`h-10 w-10 rounded-full flex items-center justify-center mr-6 ${getStatusStyle(status)}`}>
            {status === 'Completed' ? '✔️' : '○'}
          </div>

          {/* Text Content */}
          <div className="flex-1">
            <div className="flex justify-between items-center">
              <h3 className="text-xl font-semibold">{title}</h3> {/* Increased text size */}
              <span className="text-base text-gray-500">{date}</span> {/* Increased date text size */}
            </div>
            <p className={`text-md mt-2 ${status === 'Completed' ? 'text-green-600' : 'text-yellow-600'}`}>
              {status}
            </p> {/* Status below title, increased font size */}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProgressCard;