import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Sidebar';
import API_URL from '../../../../config/config'; // Assume this is where your API URL is stored
import DatePicker from 'react-datepicker';

interface ConsultationData {
    _id: string;
    createdAt: string;
    summary: string;
    recommendations: string;
    reportUrl: string;
    status: string; // 'Scheduled', 'Completed', 'Cancelled'
}

const Consultation: React.FC = () => {
    const navigate = useNavigate();
    const [consultations, setConsultations] = useState<ConsultationData[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedConsultation, setSelectedConsultation] = useState<ConsultationData | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [selectedHour, setSelectedHour] = useState<string>('10');
    const [selectedMinutes, setSelectedMinutes] = useState<string>('00');
    const [selectedPeriod, setSelectedPeriod] = useState<string>('AM');
    const [timeError, setTimeError] = useState<string | null>(null);
    const [activeTab, setActiveTab] = useState<'scheduled' | 'completed' | 'cancelled'>('scheduled');
    const consultationsPerPage = 5;
    const [page, setPage] = useState(1); // For pagination

    // Fetch consultations with 404 handling
    const fetchConsultations = async () => {
        const userId = localStorage.getItem('id');
        const token = localStorage.getItem('token');

        try {
            setLoading(true);
            const response = await fetch(`${API_URL}/consultation/user/all/${userId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.status === 404) {
                setConsultations([]); // No consultations found
                setError('No consultations found.');
                return;
            }

            if (!response.ok) {
                throw new Error('Failed to fetch consultations.');
            }

            const data = await response.json();
            setConsultations(data.consultations || []);
        } catch (error) {
            console.error('Error fetching consultations:', error);
            setError('Failed to load consultations.');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchConsultations();
    }, []);

    // Filter consultations based on tab
    const filteredConsultations = consultations.filter((consultation) => consultation.status === activeTab);

    const startIndex = (page - 1) * consultationsPerPage;
    const paginatedConsultations = filteredConsultations.slice(startIndex, startIndex + consultationsPerPage);
    const totalPages = Math.ceil(filteredConsultations.length / consultationsPerPage);

    const handleTabClick = (tab: 'scheduled' | 'completed' | 'cancelled') => {
        setActiveTab(tab);
        setPage(1); // Reset page when tab changes
    };

    const handleScheduleSubmit = async () => {
        const hour = parseInt(selectedHour);
        const minutes = parseInt(selectedMinutes);

        if (!selectedDate || !selectedTimeValid(hour, minutes, selectedPeriod)) {
            setTimeError('Please select a valid time between 10:00 AM and 6:00 PM.');
            return;
        }

        const formattedTime = `${selectedHour}:${selectedMinutes} ${selectedPeriod}`;
        const formattedDate = selectedDate.toISOString();

        try {
            const userId = localStorage.getItem('id');
            const token = localStorage.getItem('token');
            const response = await fetch(`${API_URL}/consultation/schedule`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    userId,
                    date: formattedDate,
                    time: formattedTime,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to schedule the consultation.');
            }

            alert('Your consultation has been successfully scheduled.');
            setShowScheduleModal(false);
            fetchConsultations(); // Refresh consultations after scheduling
        } catch (error) {
            console.error('Error scheduling consultation:', error);
        }
    };

    const selectedTimeValid = (hour: number, minutes: number, period: string) => {
        const isAM = period === 'AM';
        const isPM = period === 'PM';

        if (isAM && hour < 10) return false;
        if (isPM && (hour > 6 || (hour === 6 && minutes > 0))) return false;
        if (isPM && hour === 12) return false; // No 12 PM time

        return true;
    };

    const handleNextPage = () => {
        if (page < totalPages) {
            setPage(page + 1);
        }
    };

    const handlePreviousPage = () => {
        if (page > 1) {
            setPage(page - 1);
        }
    };

    return (
        <div className="flex h-screen bg-gray-50 font-sans">
            <Sidebar />
            <div className="flex-1 overflow-y-auto p-8">
                <div className="flex justify-between items-center mb-6">
                    <div>
                        <h2 className="text-3xl font-bold mb-2">Doctor Consultation Reports</h2>
                        <p className="text-gray-600">View your scheduled, completed, and cancelled consultations.</p>
                    </div>
                    <button
                        onClick={() => setShowScheduleModal(true)}
                        className="bg-darkGreen hover:bg-darkGreen text-white font-bold py-2 px-6 rounded-lg shadow-md"
                    >
                        Schedule a Consultation
                    </button>
                </div>

                {/* Tabs for Scheduled, Completed, and Cancelled Consultations */}
                <div className="mb-6 flex border-b border-gray-200">
                    <button
                        className={`px-4 py-2 -mb-px text-sm font-medium rounded-t-lg ${activeTab === 'scheduled'
                            ? 'bg-white text-darkGreen border-l border-t border-r border-gray-300'
                            : 'bg-gray-200 text-gray-600'
                            }`}
                        onClick={() => handleTabClick('scheduled')}
                    >
                        Scheduled Consultations
                    </button>
                    <button
                        className={`ml-2 px-4 py-2 -mb-px text-sm font-medium rounded-t-lg ${activeTab === 'completed'
                            ? 'bg-white text-darkGreen border-l border-t border-r border-gray-300'
                            : 'bg-gray-200 text-gray-600'
                            }`}
                        onClick={() => handleTabClick('completed')}
                    >
                        Completed Consultations
                    </button>
                    <button
                        className={`ml-2 px-4 py-2 -mb-px text-sm font-medium rounded-t-lg ${activeTab === 'cancelled'
                            ? 'bg-white text-darkGreen border-l border-t border-r border-gray-300'
                            : 'bg-gray-200 text-gray-600'
                            }`}
                        onClick={() => handleTabClick('cancelled')}
                    >
                        Cancelled Consultations
                    </button>
                </div>

                {/* Tab Content: Display consultations */}
                {loading ? (
                    <div className="flex justify-center items-center">
                        <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-darkGreen" role="status"></div>
                    </div>
                ) : error && filteredConsultations.length === 0 ? (
                    <p className="text-gray-600">{`No ${activeTab} consultations found.`}</p>
                ) : paginatedConsultations.length > 0 ? (
                    <div className="space-y-6">
                        {paginatedConsultations
                            .map((consultation) => (
                                <div
                                    key={consultation._id}
                                    className="relative bg-white rounded-lg shadow-md p-6 border-l-8 border-blue-500"
                                >
                                    <p className="absolute top-4 right-4 text-sm text-gray-600">
                                        {new Date(consultation.createdAt).toLocaleDateString()}
                                    </p>

                                    <h3 className="text-xl font-semibold">Consultation Summary</h3>
                                    <p className="text-gray-700 mt-2">{consultation.summary}</p>

                                    <button
                                        onClick={() => {
                                            setSelectedConsultation(consultation);
                                            setShowModal(true);
                                        }}
                                        className="absolute bottom-4 right-4 bg-darkGreen hover:bg-darkGreen text-white font-bold py-1 px-3 rounded-lg text-sm"
                                    >
                                        See Full Report
                                    </button>
                                </div>
                            ))}

                        <div className="flex justify-between mt-4">
                            <button
                                onClick={handlePreviousPage}
                                disabled={page                                 === 1}
                                className={`bg-darkGreen text-white font-bold py-2 px-4 rounded-lg shadow-md ${page === 1 && 'opacity-50 cursor-not-allowed'}`}
                            >
                                Previous
                            </button>
                            <button
                                onClick={handleNextPage}
                                disabled={page === totalPages}
                                className={`bg-darkGreen text-white font-bold py-2 px-4 rounded-lg shadow-md ${page === totalPages && 'opacity-50 cursor-not-allowed'}`}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                ) : (
                    <p>No consultations found.</p>
                )}
            </div>

            {/* Schedule a Consultation Modal */}
            {showScheduleModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg p-8 mx-auto w-full max-w-md h-auto max-h-[90vh] overflow-y-auto relative">
                        <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Schedule Your Consultation</h2>

                        <label className="block mb-4">
                            <span className="text-gray-700 font-medium">Select Date:</span>
                            <DatePicker
                                selected={selectedDate}
                                onChange={(date: Date | null) => setSelectedDate(date)}
                                dateFormat="dd/MM/yyyy"
                                className="border border-gray-300 p-3 rounded w-full mt-2 focus:outline-none focus:ring-2 focus:ring-darkGreen"
                                placeholderText="Select date"
                                minDate={new Date()}
                                shouldCloseOnSelect={true}
                            />
                        </label>

                        <div className="flex justify-between items-center mb-4">
                            <label className="block">
                                <span className="text-gray-700 font-medium">Hour:</span>
                                <select
                                    value={selectedHour}
                                    onChange={(e) => setSelectedHour(e.target.value)}
                                    className="border border-gray-300 p-2 rounded w-full mt-2 focus:outline-none focus:ring-2 focus:ring-darkGreen"
                                >
                                    {['10', '11', '12', '1', '2', '3', '4', '5', '6'].map((hour) => (
                                        <option key={hour} value={hour}>
                                            {hour}
                                        </option>
                                    ))}
                                </select>
                            </label>

                            <label className="block">
                                <span className="text-gray-700 font-medium">Minutes:</span>
                                <select
                                    value={selectedMinutes}
                                    onChange={(e) => setSelectedMinutes(e.target.value)}
                                    className="border border-gray-300 p-2 rounded w-full mt-2 focus:outline-none focus:ring-2 focus:ring-darkGreen"
                                >
                                    {['00', '30'].map((minute) => (
                                        <option key={minute} value={minute}>
                                            {minute}
                                        </option>
                                    ))}
                                </select>
                            </label>

                            <label className="block">
                                <span className="text-gray-700 font-medium">AM/PM:</span>
                                <select
                                    value={selectedPeriod}
                                    onChange={(e) => setSelectedPeriod(e.target.value)}
                                    className="border border-gray-300 p-2 rounded w-full mt-2 focus:outline-none focus:ring-2 focus:ring-darkGreen"
                                >
                                    {['AM', 'PM'].map((period) => (
                                        <option key={period} value={period}>
                                            {period}
                                        </option>
                                    ))}
                                </select>
                            </label>
                        </div>

                        {timeError && <p className="text-red-500 text-sm mb-4">{timeError}</p>}

                        <div className="flex justify-end mt-8 space-x-3">
                            <button
                                onClick={() => setShowScheduleModal(false)}
                                className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-6 rounded shadow-md transition-all duration-200"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleScheduleSubmit}
                                className="bg-darkGreen hover:bg-darkGreen text-white font-bold py-2 px-6 rounded shadow-md transition-all duration-200"
                            >
                                Schedule
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Modal for displaying consultation report */}
            {showModal && selectedConsultation && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-md p-8 mx-auto w-auto max-w-[90vw] h-auto max-h-[90vh] overflow-y-auto relative">
                        <div className="flex justify-between items-center mb-4">
                            <h2 className="text-2xl font-bold">Consultation Report</h2>
                            <button
                                className="bg-darkGreen text-white font-bold py-2 px-4 rounded"
                                onClick={() => setShowModal(false)}
                            >
                                Close
                            </button>
                        </div>
                        <p className="text-sm text-gray-600 mb-4">
                            Completed on: {new Date(selectedConsultation.createdAt).toLocaleDateString()}
                        </p>

                        <p className="text-gray-700 mb-6">{selectedConsultation.recommendations}</p>

                        <button
                            onClick={() => window.open(selectedConsultation.reportUrl, '_blank')}
                            className="bg-darkGreen hover:bg-darkGreen text-white font-bold py-2 px-4 rounded"
                        >
                            Download Full Report
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Consultation;