import React from 'react';

const Testimonials: React.FC = () => (
  <section id="testimonials" className="bg-cover bg-center py-36" style={{ backgroundImage: 'url(/images/testimonial.jpeg)' }}>
    <div className="container mx-auto px-6">
      <h2 className="text-3xl font-bold text-white mb-8 text-center">What Our Clients Say</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
        <div className="bg-white bg-opacity-90 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
          <div className="flex items-center mb-4">
            <span className="text-yellow-400">★★★★★</span>
          </div>
          <p className="text-gray-800 mb-4">
            "I had been feeling constantly tired and had no idea it was due to sleep apnea. Thanks to the thorough diagnostic process and support, I finally got the help I needed. Highly recommend!"
          </p>
          <p className="font-semibold text-gray-800">- Rajesh Kumar, Mumbai</p>
        </div>
        <div className="bg-white bg-opacity-90 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
          <div className="flex items-center mb-4">
            <span className="text-yellow-400">★★★★★</span>
          </div>
          <p className="text-gray-800 mb-4">
            "The in-lab sleep study was a game-changer for me. The staff were professional and made me feel at ease throughout the process."
          </p>
          <p className="font-semibold text-gray-800">- Sneha Sharma, Delhi</p>
        </div>
        <div className="bg-white bg-opacity-90 p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
          <div className="flex items-center mb-4">
            <span className="text-yellow-400">★★★★★</span>
          </div>
          <p className="text-gray-800 mb-4">
            "I was skeptical about home sleep tests, but this service made it easy and stress-free. Now I'm on a treatment plan that works!"
          </p>
          <p className="font-semibold text-gray-800">- Ravi Mehta, Bangalore</p>
        </div>
      </div>
    </div>
  </section>
);

export default Testimonials;