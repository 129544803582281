import React, { useState } from 'react';

const Footer: React.FC = () => {
    const [isModalOpen, setModalOpen] = useState(false);

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
    };

    return (
        <footer id="footer" className="bg-white py-20 relative">
            {/* Thinner line above the footer */}
            <div className="w-full h-0.5 bg-gray-300 mb-8"></div>

            <div className="container mx-auto px-6 text-center lg:text-left">
                <div className="lg:flex lg:justify-between lg:space-x-12 mb-8">
                    {/* Logo */}
                    <div className="lg:w-1/4 mb-8 lg:mb-0 text-center lg:text-left"> 
                        <img src="/logo.png" alt="Logo" className="h-20 mb-4 mx-auto lg:mx-0" /> 
                    </div>

                    {/* Company */}
                    <div className="lg:w-1/4 mb-8 lg:mb-0 text-center lg:text-left"> 
                        <h3 className="text-lg font-semibold text-gray-900 mb-4">Company</h3>
                        <ul className="mb-6">
                            <li><a href="/terms-of-use" className="text-gray-600 hover:text-gray-900">Terms of Use</a></li>
                            <li><a href="/privacy-policy" className="text-gray-600 hover:text-gray-900 mb-8">Privacy Policy</a></li> {/* Increased margin below */}
                        </ul>
                        <p className="text-gray-600 mb-2">Follow Us</p>
                        <div className="flex justify-center lg:justify-start space-x-4"> {/* Center icons on mobile, left align on large */}
                            <a href="https://www.facebook.com/yournidra/" className="text-gray-600 hover:text-gray-900">
                                <i className="fab fa-facebook-f"></i>
                            </a>
                            <a href="https://x.com/yournidra" className="text-gray-600 hover:text-gray-900">
                                <i className="fab fa-twitter"></i>
                            </a>
                            <a href="https://www.instagram.com/yournidra/" className="text-gray-600 hover:text-gray-900">
                                <i className="fab fa-instagram"></i>
                            </a>
                        </div>
                    </div>

                    {/* Contact Section */}
                    <div className="lg:w-1/4 mb-8 lg:mb-0 text-center lg:text-left"> 
                        <h3 className="text-lg font-semibold text-gray-900 mb-4">Contact</h3>
                        <p className="text-gray-600">Phone</p>
                        <p className="text-gray-600 mb-6">+91 96437 27987</p>
                        
                        <p className="text-gray-600">Email</p>
                        <p className="text-gray-600 mb-4">hello@yournidra.com</p>
                        
                    </div>

                    {/* Contact Form */}
                    <div className="lg:w-1/4 text-center lg:text-left"> {/* Center on mobile, left on large screens */}
                        <h3 className="text-lg font-semibold text-gray-900 mb-4">Want to join our Newsletter?</h3>
                        <form onSubmit={handleSubmit} className="flex space-x-2 justify-center lg:justify-start"> {/* Center form on mobile */}
                            <input
                                type="email"
                                placeholder="Your email"
                                className="w-full px-4 py-2 text-gray-900 rounded-md focus:outline-none border border-gray-300 focus:border-gray-500"
                                required
                            />
                            <button
                                type="submit"
                                className="bg-darkGreen text-white px-6 py-2 rounded-md hover:bg-blue-700 transition-all"
                            >
                                →
                            </button>
                        </form>
                    </div>
                </div>

                {/* Bottom legal section */}
                <div className="text-center border-t border-gray-300 pt-4">
                    <p className="text-gray-600">© 2024 VITALEDGE HEALTH PRIVATE LIMITED. All rights reserved.</p>
                </div>
            </div>

            {/* Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-20">
                    <div className="bg-white p-6 rounded-lg shadow-lg">
                        <h3 className="text-xl font-bold mb-4">Welcome to YourNidra!</h3>
                        <p>Thank you for joining our newsletter. We will keep you updated with the latest news in sleep disorders.</p>
                        <button onClick={handleCloseModal} className="text-darkGreen mt-4">
                            Close
                        </button>
                    </div>
                </div>
            )}
        </footer>
    );
};

export default Footer;