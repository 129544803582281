import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Sidebar from '../../Sidebar';
import API_URL from '../../../../config/config'; // Assume this is where your API URL is stored
import DatePicker from 'react-datepicker';

interface SleepStudyData {
    _id: string;
    userId: string;
    createdAt: string;
    date: string;  // For scheduled date
    status: string; // 'Scheduled', 'Completed'
    ahi?: number; // Apnea-Hypopnea Index (only for completed studies)
    minOxygen?: number; // Minimum Oxygen Saturation (only for completed studies)
    recommendations?: string; // Recommendations for the patient (only for completed studies)
    reportUrl?: string; // URL to download/view the full report (only for completed studies)
}

const SleepStudy: React.FC = () => {
    const navigate = useNavigate();
    const [sleepStudies, setSleepStudies] = useState<SleepStudyData[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedStudy, setSelectedStudy] = useState<SleepStudyData | null>(null);
    const [showModal, setShowModal] = useState(false);
    const [showScheduleModal, setShowScheduleModal] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Date | null>(null);
    const [activeTab, setActiveTab] = useState<'scheduled' | 'completed'>('scheduled');

    const fetchSleepStudies = async () => {
        const userId = localStorage.getItem('id');
        const token = localStorage.getItem('token');

        try {
            setLoading(true);

            const response = await fetch(`${API_URL}/sleep-study/user/all/${userId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();
            const allStudies = data.sleepStudyData || [];

            setSleepStudies(allStudies);

        } catch (err) {
            console.error('Error loading sleep studies', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSleepStudies();
    }, []);

    const handleSeeReportClick = (study: SleepStudyData) => {
        setSelectedStudy(study);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleScheduleClick = () => {
        setShowScheduleModal(true);
    };

    const handleScheduleSubmit = async () => {
        if (!selectedDate) {
            alert('Please select a date.');
            return;
        }

        const formattedDate = selectedDate.toISOString();

        try {
            const userId = localStorage.getItem('id');
            const token = localStorage.getItem('token');

            await fetch(`${API_URL}/sleep-study/schedule`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    userId,
                    date: formattedDate,
                    status: 'Scheduled',
                }),
            });

            alert('Your sleep study has been successfully scheduled.');
            setShowScheduleModal(false);
            fetchSleepStudies(); // Refresh the list after scheduling
        } catch (error) {
            console.error('Error scheduling sleep study:', error);
        }
    };

    const handleDeleteSchedule = async (studyId: string) => {
        // eslint-disable-next-line no-restricted-globals
        if (confirm("Are you sure you want to delete this scheduled study?")) {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${API_URL}/sleep-study/${studyId}`, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    alert("Sleep study schedule deleted successfully.");
                    fetchSleepStudies(); // Refresh the list
                } else {
                    throw new Error("Failed to delete the sleep study schedule.");
                }
            } catch (error) {
                console.error(error);
            }
        }
    };

    const handleTabClick = (tab: 'scheduled' | 'completed') => {
        setActiveTab(tab);
    };

    return (
        <div className="flex h-screen bg-gray-50 font-sans">
            <Sidebar />
            <div className="flex-1 overflow-y-auto p-8">
                <div className="flex justify-between items-center mb-6">
                    <div>
                        <h2 className="text-3xl font-bold mb-2">YourNidra Sleep Studies</h2>
                        <p className="text-gray-600">View scheduled and completed sleep studies.</p>
                    </div>
                    <button
                        onClick={handleScheduleClick}
                        className="bg-darkGreen hover:bg-darkGreen text-white font-bold py-2 px-6 rounded-lg shadow-md"
                    >
                        Schedule a Sleep Study
                    </button>
                </div>

                {/* Tabs for Scheduled and Completed Studies */}
                <div className="mb-6 flex border-b border-gray-200">
                    <button
                        className={`px-4 py-2 -mb-px text-sm font-medium rounded-t-lg ${activeTab === 'scheduled'
                                ? 'bg-white text-darkGreen border-l border-t border-r border-gray-300'
                                : 'bg-gray-200 text-gray-600'
                            }`}
                        onClick={() => handleTabClick('scheduled')}
                    >
                        Scheduled Sleep Studies
                    </button>
                    <button
                        className={`ml-2 px-4 py-2 -mb-px text-sm font-medium rounded-t-lg ${activeTab === 'completed'
                                ? 'bg-white text-darkGreen border-l border-t border-r border-gray-300'
                                : 'bg-gray-200 text-gray-600'
                            }`}
                        onClick={() => handleTabClick('completed')}
                    >
                        Completed Sleep Studies
                    </button>
                </div>

                {/* Tab Content: Scheduled Studies */}
                {activeTab === 'scheduled' && (
                    <>
                        {loading ? (
                            <div className="flex justify-center items-center">
                                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-darkGreen" role="status"></div>
                            </div>
                        ) : sleepStudies.filter(study => study.status === 'Scheduled').length > 0 ? (
                            <div className="space-y-6">
                                {sleepStudies.filter(study => study.status === 'Scheduled').map((study) => (
                                    <div key={study._id} className="relative bg-white rounded-lg shadow-md p-6 border-l-8 border-blue-500">
                                        <p className="text-sm text-gray-600 mb-2">
                                            Scheduled for: {new Date(study.date).toLocaleDateString()}
                                        </p>
                                        <div className="flex justify-between items-end">
                                            <p className="text-gray-700 font-bold">Status: {study.status}</p>
                                            <button
                                                onClick={() => handleDeleteSchedule(study._id)}
                                                className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-4 rounded-lg shadow-md"
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No sleep study scheduled</p>
                        )}
                    </>
                )}

                {/* Tab Content: Completed Studies */}
                {activeTab === 'completed' && (
                    <>
                        {loading ? (
                            <div className="flex justify-center items-center">
                                <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-darkGreen" role="status"></div>
                            </div>
                        ) : sleepStudies.filter(study => study.status === 'Completed').length > 0 ? (
                            <div className="space-y-6">
                                {sleepStudies.filter(study => study.status === 'Completed').map((study) => (
                                    <div key={study._id} className="relative bg-white rounded-lg shadow-md p-6 border-l-8 border-blue-500">
                                        <p className="absolute top-4 right-4 text-sm text-gray-600">{new Date(study.createdAt).toLocaleDateString()}</p>
                                        <div className="flex justify-between items-start">
                                            <div>
                                                <h3 className="text-xl font-semibold">Sleep Study Report</h3>
                                                <p className="text-gray-700 mt-2">AHI: {study.ahi} events/hour (Moderate Apnea)</p>
                                                <p className="text-gray-700">Minimum Oxygen Saturation: {study.minOxygen}%</p>
                                            </div>
                                        </div>
                                        <button
                                            onClick={() => handleSeeReportClick(study)}
                                            className="absolute bottom-4 right-4 bg-darkGreen hover:bg-darkGreen text-white font-bold py-1 px-3 rounded-lg text-sm inline-flex items-center"
                                            aria-label="See full report"
                                        >
                                            <svg
                                                className="fill-current w-3 h-3 mr-1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 20 20"
                                            >
                                                <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
                                            </svg>
                                            <span>See Report</span>
                                        </button>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No sleep study completed</p>
                        )}
                    </>
                )}

                {/* Schedule a Sleep Study Modal */}
                {showScheduleModal && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg shadow-lg p-8 mx-auto w-full max-w-md h-auto max-h-[90vh] overflow-y-auto relative">
                            <h2 className="text-2xl font-semibold text-gray-800 mb-6 text-center">Schedule Your Sleep Study</h2>

                            <p className="text-gray-600 leading-relaxed text-justify mb-6">
                                A home sleep study is a convenient and comfortable way to monitor your sleep. You'll use a portable device
                                that tracks your breathing, oxygen levels, and other vital parameters while you sleep at home.
                            </p>

                            <label className="block mb-4">
                                <span className="text-gray-700 font-medium">Select Date:</span>
                                <DatePicker
                                    selected={selectedDate}
                                    onChange={(date: Date | null) => {
                                        setSelectedDate(date);
                                    }}
                                    dateFormat="dd/MM/yyyy"
                                    className="border border-gray-300 p-3 rounded w-full mt-2 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    placeholderText="Select date"
                                    minDate={new Date()}
                                    shouldCloseOnSelect={true}
                                />
                            </label>

                            <div className="flex justify-end mt-8 space-x-3">
                                <button
                                    onClick={() => setShowScheduleModal(false)}
                                    className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-6 rounded shadow-md transition-all duration-200"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleScheduleSubmit}
                                    className="bg-darkGreen hover:bg-darkGreen text-white font-bold py-2 px-6 rounded shadow-md transition-all duration-200"
                                >
                                    Schedule
                                </button>
                            </div>
                        </div>
                    </div>
                )}

                {/* Modal for displaying sleep study report */}
                {showModal && selectedStudy && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                        <div className="bg-white rounded-lg shadow-md p-8 mx-auto w-auto max-w-[90vw] h-auto max-h-[90vh] overflow-y-auto relative">
                            <div className="flex justify-between items-center mb-4">
                                <h2 className="text-2xl font-bold">Sleep Study Report</h2>
                                <button
                                    className="bg-darkGreen text-white font-bold py-2 px-4 rounded"
                                    onClick={handleCloseModal}
                                >
                                    Close
                                </button>
                            </div>
                            <p className="text-sm text-gray-600 mb-4">Completed on: {new Date(selectedStudy.createdAt).toLocaleDateString()}</p>

                            <p className="text-gray-700 mb-6">AHI: {selectedStudy.ahi} events/hour</p>
                            <p className="text-gray-700 mb-6">Minimum Oxygen Saturation: {selectedStudy.minOxygen}%</p>
                            <p className="text-gray-700 mb-6">Recommendations: {selectedStudy.recommendations}</p>

                            <button
                                onClick={() => window.open(selectedStudy.reportUrl, '_blank')}
                                className="bg-darkGreen hover:bg-darkGreen text-white font-bold py-2 px-4 rounded"
                            >
                                Download Full Report
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SleepStudy;